* {
    box-sizing: border-box
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
    outline: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}


.container {
    margin: 0 auto;
    max-width: 1120px;
    min-width: 290px;
    padding: 0 15px;
}

@media (min-width: 450px) {
    .container {
        padding: 0 35px;
    }
}

@media (min-width: 768px) {
    .title-container {
        width: 80%;
    }
}

@media screen and (min-width: 1600px) {
    .title-container {
        width: 60%;
    }
}

/*____________for mouse_____________*/

.mouse {
    position: relative;
    display: block;
    width: 20px;
    height: 30px;
    margin: 0 auto;
    border: solid 1px #fff;
    border-radius: 8px;
}

.scroll-down {
    display: block;
    width: 4px;
    height: 4px;
    margin: 15% auto auto;
    -webkit-animation: scroll-inner 1.5s;
    animation: scroll-inner 1.5s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 50%;
    background: #fff;
}

@-webkit-keyframes scroll-inner {
    from {
        margin-top: 15%;
        opacity: 1;
    }
    to {
        margin-top: 75%;
        opacity: 0;
    }
}
@keyframes scroll-inner {
    from {
        margin-top: 15%;
        opacity: 1;
    }
    to {
        margin-top: 75%;
        opacity: 0;
    }
}

/*____________for burger menu_____________*/
.hamburgerHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.hamburger {
    margin: 0 auto;
    width: 24px;
    height: 18px;
    position: relative;
    cursor: pointer;
}

.hamburger .bar {
    padding: 0;
    width: 24px;
    height: 2px;
    background: white;
    display: block;
    border-radius: 2px;
    transition: all 0.4s ease-in-out;
    position: absolute;
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 7px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 1px;
}


.hamburger .bar1 {
    transform-origin: 5%;
}

.hamburger .bar4 {
    transform-origin: 5%;
}

.hamburgerCheckbox:checked + label > .hamburger > .bar1 {
    transform: rotate(45deg);
    height: 2px;
    width: 24px;
}

.hamburgerCheckbox:checked + label > .hamburger > .bar3 {
    transform: rotate(45deg);
    height: 2px;
    background: transparent !important;
}

.hamburgerCheckbox:checked + label > .hamburger > .bar2 {
    transform: rotate(-45deg);
    height: 2px;
    background: transparent;
}

.hamburgerCheckbox:checked + label > .hamburger > .bar4 {
    transform: rotate(-45deg);
    height: 2px;
    width: 24px;
}

/*__________slick carousel ( dots )_________*/

.slick-slide {
    height: auto !important;
}
.slick-slide > div {
    display: flex;
    align-items: flex-end;
    height: 100% !important;
}
.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
}

.slick-slide > .slick-center {
    transition: all .5s ease;
}

.slick-dots {
    text-align: center;
    padding: 0;
}

.slick-dots li {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button {
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    border: 1px solid #00000020;
}

.slick-dots li.slick-active button:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: #2B6DDC;
}

.slick-dots.light-dots li button:before {
    color: rgba(21, 25, 32, 0.2);
}

.ellipsisText4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0!important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: initial!important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0!important;
}

